.c-legal {
  &__hero {
    background: #fffdf9;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    min-height: 20rem;
    margin-bottom: 3rem;
    padding: 120px 0 60px;

    h3 {
      font-size: 2.8rem;
      margin: 4px 0 8px;
      max-width: 520px;
      line-height: 40px;
      font-weight: 400;
    }

    h5 {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: uppercase;
      font-family: var(--font-primary);
      margin: 4px 0 16px;
    }

    h6 {
      font-size: 12px;
      line-height: 120%;
      font-weight: 400;
      letter-spacing: 2px;
      text-transform: capitalize;
      margin: 16px 0 8px;
    }
  }

  &__text {
    h2 {
      font-size: 22px;
      margin: 24px 0 12px;
      font-weight: 500;
    }

    p {
      font-size: 16px;
      line-height: 26px;
      font-weight: 300;
      margin: 0.8rem 0 1.2rem;
    }

    ul {
      li {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 12px;
      }
    }
  }
}