:root {
  --font-heading: "Rotunda",
    Arial,
    sans-serif;
  --font-text: "Rotunda",
    Arial,
    sans-serif;
  --white: #fff;
  --black: #000;
  --white-rgb: 255, 255, 255;
  --black-rgb: 0, 0, 0;
  --theme-black: #0a2440;
  --theme-black-rgb: 10, 36, 64;
  --theme-primary: #fbc124;
  --theme-primary-hsl: 44, 96%, 56%;
  --theme-accent: #ffe254;
  --theme-accent-rgb: 2555, 226, 84;
}