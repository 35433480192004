.c-home {
  position: relative;
  // min-height: 60vh;
  min-height: 100vh;
  background: rgba(var(--white-rgb), 1);
  // overflow: hidden;
  padding-bottom: 2rem;
}

.c-manifest {
  padding: 9.2rem 0 13rem;
  z-index: 5000;

  @media screen and (max-width: 760px) {
    padding: 12.8rem 0.2rem 14rem;
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding-top: 13rem;
  }

  @media only screen and (min-width: 1400px) {
    padding: 14rem 0 4.2rem;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    // @media screen and (max-width: 760px) {
    //   margin-left: 1rem;
    // }

    // &::after {
    //   content: url(./images/swup-alt.svg);
    //   display: block;
    //   position: absolute;
    //   top: 62px;
    //   left: -2px;
    // }

    h2 {
      font-family: var(--font-text);
      font-weight: 500;
      font-size: 72px;
      color: rgba(var(--theme-black-rgb), 1);
      line-height: 88px;
      max-width: 860px;
      position: relative;
      letter-spacing: -.98px;
      isolation: isolate;
      z-index: 10;
      overflow: hidden;
      position: relative;

      @media screen and (max-width: 760px) {
        font-size: 38px;
        line-height: 56px;
      }

      @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
        font-size: 52px;
      }

      &>span {
        display: flex;
        justify-content: center;
        width: 105%;
        gap: 16px;

        @media screen and (max-width: 760px) {
          display: initial;
          flex-direction: column;
        }

        @media only screen and (min-width: 1400px) {
          margin-left: 0.4rem;
        }
      }

      i {
        display: inline-flex;
        height: 76px;
        overflow: hidden;
        position: relative;
        min-width: 340px;
        font-style: normal;

        @media screen and (max-width: 760px) {
          justify-content: center;
          height: 60px;

        }

        span {
          position: absolute;
          top: -82px;
          white-space: nowrap;
          transition: all 0.3s ease-in-out;
          display: flex;
          align-items: center;
          font-size: 56px;

          @media screen and (max-width: 760px) {
            font-size: 32px;
            top: -74px;
          }

          &.animated-in {
            // transform: translateY(-54px);
            animation-name: SlideIn;
            animation-duration: .6s;
            animation-fill-mode: forwards;
            // animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
            // animation-iteration-count: infinite;
          }

          img {
            max-width: 50px;
            margin: -5px 8px 0 2px;
          }
        }
      }

      &:after {
        content: url(./images/stroke.svg);
        display: inline-block;
        position: absolute;
        left: 40%;
        top: 22px;
        z-index: -1;
        isolation: isolate;

        @media screen and (max-width: 760px) {
          content: url(./images/stroke-mobile.svg);
          left: 120px;
          top: 20px;
          width: 30px;
        }
      }

    }

    p {
      font-size: 20px;
      letter-spacing: -.1px;
      line-height: 40px;
      margin: 0.4rem 0 2.8rem;
      max-width: 628px;
    }

  }

  &__button {
    a {
      background: var(--theme-primary);
      border-radius: 8px;
      color: var(--theme-black);
      font-size: 1.6rem;
      line-height: 1.6rem;
      padding: 1.6rem 3.2rem;
      transition: all .32s ease;
    }
  }

}

.c-media {
  position: relative;

  &__images-wrap {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 4rem;

  }

  &__image {
    &--1 {
      width: 23%;
      bottom: 50px;
      z-index: 100;
      position: relative;

      @media screen and (max-width: 760px) {
        width: 57%;
        bottom: initial;
      }

      @media only screen and (min-width: 1400px) {
        width: 25%;
        bottom: -50px;
      }
    }

    &--2 {
      width: 14%;
      left: 55.6%;
      top: -78px;
      position: absolute;

      @media screen and (max-width: 760px) {
        width: 48%;
        left: 54.6%;
        top: -137px;
      }

      @media only screen and (min-width: 1400px) {
        width: 18%;
        left: 54.3%;
        top: -32px;
      }

    }

    &--3 {
      width: 20%;
      left: 55.3%;
      top: 120px;
      position: absolute;

      @media screen and (max-width: 760px) {
        width: 54%;
        left: 55.3%;
        top: 44px;
      }

      @media only screen and (min-width: 1400px) {
        width: 20%;
        left: 55.3%;
        top: 280px;
      }
    }

    &--4 {
      width: 25%;
      left: 24%;
      top: -20px;
      position: absolute;

      @media screen and (max-width: 760px) {
        width: 60%;
        left: -8%;
        top: -11px;
      }

      @media only screen and (min-width: 1400px) {
        width: 25%;
        left: 24%;
        top: 160px;
      }
    }

  }
}


@keyframes SlideIn {
  0% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 80px, 0);
  }
}


// .kurobi-pay {
//   &__background {
//     z-index: 150;
//     position: relative;
//   }

//   &__glow-wrap {
//     width: 100%;
//     position: relative;
//     height: 100%;
//     filter: blur(240px);
//   }

//   &__glow {
//     &--one {
//       position: absolute;
//       border-radius: 362.53px;
//       transform: rotate(-35.6deg);
//       mix-blend-mode: overlay;
//       right: 261px;
//       background: radial-gradient(65.67% 65.67% at 41.2% 34.33%, rgba(224, 111, 252, .322) 40%, rgba(169, 111, 252, .671) 100%);
//       top: 200px;
//       width: 500px;
//       height: 500px;

//       @-moz-document url-prefix('') {
//         background: radial-gradient(65.67% 65.67% at 41.2% 34.33%,
//             #e06ffc21 40%,
//             #a96ffcab 100%);

//       }

//     }
//   }
// }