.c-footer {
  display: flex;
  height: 72px;
  min-height: 72px;
  width: 100%;
  background: transparent;

  &__links {
    padding: 52px 0;
    gap: 16px;

    @media screen and (max-width: 760px) {
      padding: 20px 0 32px;
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
      padding: 20px 0 32px;
    }

  }

}