.c-header {
  display: flex;
  height: 72px;
  min-height: 72px;
  padding: 0 52px;
  width: 100%;
  background: transparent;
  position: absolute;
  inset: 4px 0 0 0;
  z-index: 50;

  @media screen and (max-width: 760px) {
    padding: 0 12px;
  }
}

.c-nav {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  align-items: center;
  width: 100%;

  &__logo {
    isolation: isolate;
  }

  &__menu {
    ul {
      list-style: none;
      display: flex;
      gap: 0 36px;
      font-size: 15px;
      justify-content: flex-start;
      padding: 0;
      margin: 0;
    }

    @media screen and (max-width: 760px) {
      display: none;
    }
  }

  &__buttons {
    a {
      background: var(--theme-primary);
      border-radius: 8px;
      color: var(--theme-black);
      font-size: 1.4rem;
      line-height: 1.6rem;
      padding: 1.2rem 2.4rem;
      transition: all .32s ease;

      @media screen and (max-width: 760px) {
        padding: 1.2rem 1.2rem;
      }
    }

  }
}